/*
#appstle-widget-template .widgetSellingPlanWrapper input:checked+label {
    background: #000000;
    border-radius: unset !important;
}
#appstle-widget-template  .appstle_sub_widget input[type=radio]+label:before {
    border-radius: 0 !important;
    border: 1px solid #000000;
    padding: 0;
}
#appstle-widget-template .widgetSellingPlanWrapper input:checked+label+span {
    background: #ff9999 !important;
    color: #ffffff;
    border: 1px solid #000000;
}
#appstle-widget-template .widgetSellingPlanWrapper input:checked+label+span {
    background: #008060 !important;
    color: #ffffff;
    border: 1px solid #008060;
}
#appstle-widget-template  span.appstle_sellingPlan_discountText {
    background: #ffffff!important;
    color: #000000;
    border: 1px solid;
}
.appstle_sub_widget input[name=selling_plan]:checked + label span.appstle_lowercase.appstle_sellingPlan_formattedCompareAtPrice.appstle_price_block span.money.conversion-bear-money {
    color: #ffffff;
}
.appstle_sub_widget input[name=selling_plan]:checked + label span.appstle_lowercase.appstle_sellingPlan_formattedCompareAtPrice.appstle_price_block {
    color: #ffffff;
}
.appstleSelectedSellingPlanOptionDescription {
    background: #008060;
    text-transform: uppercase;
    font-size: 10px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
}
.appstle_widget_title {
    padding-bottom: 10px;
}*/

/* Use this file to add custom css to the theme. */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

/* Start product slider css here */
.product__media__main {
  display: flex;
  flex-wrap: wrap;
}
.product__media__left {
  flex: 0 0 8%;
  max-width: 8%;
}
.product__media_right {
  flex: 0 0 90%;
  max-width: 90%;
}
.product__media__left .image__inner img {
  max-width: 100%;
  border-radius: 5px;
}
.product__media__left .image__inner {
  width: 50px;
  margin-bottom: 10px;
}
.product__media_right .image__inner img {
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
}
.product__thumbnail__left.slick-slide.slick-current .image__inner {
  border: 1px solid #000;
  border-radius: 5px;
}

.product__media_right .slick-arrow {
  position: absolute;
  bottom: 0;
  top: auto;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #fff;
}

.product__media_right button.slick-next {
  right: 20px;
  background: #fff
    url(https://cdn.shopify.com/s/files/1/0039/6908/9606/files/next.png?v=1699006030);
  border-radius: 50px;
  background-position: center center;
  background-size: 30px;
  background-repeat: no-repeat;
}
.product__media_right button.slick-prev {
  left: auto;
  right: 90px;
  z-index: 4;
  background: #fff
    url(https://cdn.shopify.com/s/files/1/0039/6908/9606/files/left.png?v=1699006296);
  border-radius: 50px;
  background-position: center center;
  background-size: 30px;
  background-repeat: no-repeat;
}

.product__media_right .slick-prev:before,
.product__media_right .slick-next:before {
  display: none;
}
/* End product slider css here */

.title_large {
  font-size: 64px;
  line-height: 78px;
  font-weight: 500;
  font-family: var(--font-heading);
}

.title_medium {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  font-family: var(--font-heading);
}

.image-split-heading {
  position: absolute;
  width: 900px;
  top: 160px;
  left: 50%;
  transform: translatex(-50%);
  z-index: 1;
  text-align: center;
}

.btn.bottom-border-button {
  padding: 0;
  border-bottom: 2px solid #fff;
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font-heading);
  font-style: italic;
  text-transform: capitalize;
}

.btn.bottom-border-button span svg {
  display: inline-block;
  margin-left: 15px;
}

.homepage-multicolm .section {
  padding-top: 35px;
  padding-bottom: 35px;
}

.homepage-multicolm .multi-column__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.multi-column__grid-item {
  width: fit-content;
}

.multi-column__grid-item-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-transform: initial;
}

.multi-column__grid-item-text {
  margin-top: 10px;
}

.multi-column__grid-item-text p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
}

.complete-the-look__heading,
.featured-collection-slider__heading {
  color: #272727;
}

.complete-the-look__subheading.section-introduction__subheading {
  margin-top: 15px;
  margin-bottom: 32px;
}

.complete-the-look__subheading p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  color: rgba(39, 39, 39, 0.8);
}

.complete-the-look__products .mini-product-image-hover-link {
  position: relative;
  z-index: 0;
}

.product-card-mini .product-card-mini__inner .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-heading);
  font-weight: 500;
  padding: 25px 30px;
  background: #ffffff;
  border: 1px solid rgba(39, 39, 39, 0.5);
  width: 100%;
  max-width: 176px;
  height: 70px;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.product-card-mini__inner.product-card-mini__hover:hover .btn {
  opacity: 1;
  visibility: visible;
}

.product-card-mini__product-vendor {
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  color: #272727;
  margin-bottom: 5px;
  display: none;
}

.product-card-mini__product-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  font-family: var(--font-heading);
  text-transform: capitalize;
}

.product-card-mini__price {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: rgba(39, 39, 39, 0.7);
}

.product-card-mini__price .money {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: rgba(39, 39, 39, 1);
}

.featured-collection-slider__content {
  margin-top: 43px;
}

.featured-collection-slider__navigation-buttons
  .carousel__navigation-button--back {
  transform: rotate(180deg);
  margin-right: 65px;
  margin-bottom: 32px;
}

.featured-collection-slider__navigation-buttons
  .carousel__navigation-button--forward {
  margin: unset;
  margin-bottom: 14px;
}

.product-item__text .product-item__product-vendor {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #272727;
  opacity: 1;
  margin-bottom: 5px;
  margin-top: 0;
  text-transform: initial;
}

.product-item__text .product-item__product-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  font-family: var(--font-heading);
  text-transform: capitalize;
  margin: 0;
}

.product-item__text .product-item__price {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #272727b3;
  margin-top: 5px;
  margin-bottom: 0;
}

.product-item__text .product-item__price .money {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #272727;
}

.discount-content {
  background-color: #f3f3f3;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.discount_text {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: #d90000;
  margin-top: 5px;
  min-height: 19px;
}

.product__block--product-header-inner .discount_text {
  margin-top: 20px;
}

.grid-item__heading {
  color: #fff;
}

.footer__bottom {
  /*   display: none; */
  margin-top: 30px;
}

.footer__credits {
  display: flex;
  gap: 4px;
}

.testimonial-review {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 41px;
  gap: 33px;
  row-gap: 20px;
}

.review-svg svg {
  display: block;
}

.review-text {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 26.4px;
  color: #272727;
}

.testimonials__navigation-button .icon svg {
  color: #282828;
}

.home-i-w-t .image-with-text-split__text-container-text {
  margin-top: 40px;
}

.home-i-w-t .image-with-text-split__text-container-text p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.footer__header.ff-body {
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.footer__links li a {
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  color: #ffffff;
}

.footer__header.ff-body .footer__heading {
  text-transform: capitalize;
}

.footer__group--1 .footer__text {
  margin-top: 30px;
  margin-bottom: 0;
}

.footer__group--1 .footer__text p {
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
}

.footer__newsletter .footer__newsletter-button {
  background-color: #fff;
  color: #272727;
  padding: 25px 40px;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-heading);
  height: 70px;
  font-weight: 500;
  letter-spacing: 0;
}

.footer__newsletter .input[type="email"] {
  color: inherit;
  height: 70px;
  padding-top: 40px;
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  padding-right: 180px;
  outline: none;
}

.footer__newsletter input:placeholder {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #fff !important;
}

.footer__newsletter input::-webkit-input-placeholder,
.footer__newsletter input::-moz-placeholder,
.footer__newsletter input::-ms-input-placeholder,
.footer__newsletter input::placeholder {
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  color: #ffffff;
}

.product__title.title_medium {
  text-transform: capitalize;
  color: #272727;
  margin-bottom: 10px;
}

.product__vendor a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #000000;
}

.product-short-descrp {
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  color: rgba(39, 39, 39, 0.9);
}

.product__price,
.product__price .money {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: var(--font-heading);
  color: #272727;
}

.product__label {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #000000;
}

.product__item .quantity-input {
  background-color: #f2f2f2;
  border: none;
  height: 39px;
}

.product__item .quantity-input .quantity-input__button {
  padding: 10px 20px;
}

.product__item .quantity-input input {
  width: 57px;
  pointer-events: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #272727;
  font-family: var(--font-heading);
}

.product-form__item .btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-heading);
  padding: 20px 60px;
}

.product .accordion__label .ff-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: var(--font-heading);
  text-transform: capitalize;
  color: #272727;
}

.product .accordion__content p {
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  color: rgba(39, 39, 39, 0.8);
}

.main-product-recommendation {
  margin-top: 30px;
}

.main-product-recommendation .product-item__product-title a {
  text-decoration: none;
}

.main-product-recommendation .product-item__product-vendor {
  font-size: 12px;
  line-height: 16px;
}

.main-product-recommendation .product-item__text .product-item__price {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #272727b3;
}

.left-side-blocks .accordion:last-child .accordion__inner[data-open="true"] {
  border-bottom: 0;
}

.product-page-faq .collapsible-row-list__heading + .collapsible-row-list-item {
  margin-top: 23px;
}

.button-show ~ .collapsible-row-list-item {
  display: none;
}

.more-faq {
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #272727;
  font-family: var(--font-heading);
  text-decoration: underline;
  text-underline-offset: 3px;
  margin-top: 23px;
}

.icon-with-text {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.icon-with-text-icon {
  margin-right: 24px;
}

.icon-with-text-icon img {
  display: block;
  width: 35px;
  height: 35px;
}

.icon-with-text-icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.icon-with-text-heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: var(--font-heading);
  margin-bottom: 10px;
}

.icon-with-text-text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
}

.image-with-text-icon .image-with-text-split__image .image__img {
  height: 100%;
}

.collection-banner:not(.collection--has-image) {
  padding-top: 85px;
  padding-bottom: 85px;
  position: relative;
}

.collection-banner:not(.collection--has-image):after {
  content: "";
  display: block;
  width: 100%;
  max-width: 1400px;
  padding: 0 var(--space-outer);
  height: 1px;
  background-color: #2727274d;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.faq-hide {
  display: none !important;
}


.collection__main-area
  .pagination__item--arrow
  .pagination__navigation-button
  .icon {
  width: 13px;
  height: 13px;
}

.pagination__list + .pagination__footer {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #272727;
  margin-top: 35px;
}
.desktop-hide {
  display: none;
}
.mobile-quick-btn {
  display: none;
}
.complete-the-look__inner .button-block__button {
  display: none;
}
.header__row.header__row-desktop.lower .header__logo {
  margin: 11px 0;
}
.product__policies {
  display: none;
}
.product__block .product__callouts-item {
  align-items: center;
  padding-left: 10px;
  padding: 12px 0 12px 10px;
}
.product__callouts-item-icon .image__inner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product-badge.product-badge {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 6px;
  font-weight: 400;
  font-family: var(--font-heading);
  width: 122px;
  height: 39px;
  justify-content: center;
}
.header__links .header__links-list span {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: 0;
  color: #272727;
}
.header .icon-button__icon .icon {
  width: 23px;
  height: 23px;
}
.product__block--product-header-inner .product-badges {
  margin-bottom: 30px;
}
.custom-field + .shopify-app-block,
.product__block--product-header + .shopify-app-block {
  margin-bottom: 30px;
}
.stamped-widget-poweredby {
  display: none !important;
}
body select#stamped-sort-select {
  border-top: 1px solid #eee !important;
}
body .stamped-reviews-filter {
  padding-top: 30px;
}
.product__block.product__block-callouts {
  margin-top: 27px;
}
.product-page-faq .collapsible-row-list-item__heading {
  text-transform: initial;
}
/* .footer .image.footer__image {
    max-width: 80px;
} */
.footer__newsletter p {
  font-family: var(--font-body);
}
.header__links-primary-scroll-container button.scroll-button {
  display: none;
}
.header__links-primary-scroll-container .scroll-overflow-indicator-wrap {
  display: none;
}
.home-grid-image .grid-item__text-container {
  max-width: 100%;
}
.home-video .video-hero__text-container {
  max-width: 100%;
}
.header__menu-icon[aria-expanded="true"] .icon-button-header-menu-new {
  opacity: 0;
  transform: scale(0.8);
  transition-delay: 0s;
}
.header .header__menu-icon .icon-header-menu-close {
  width: 28px;
  height: 28px;
}
body .stamped-fa-star-o {
  color: #aaa;
}
[data-js-cart-count] {
  background-color: #ff9999;
  color: #272727;
  box-shadow: unset;
  font-size: 12px;
}
.featured-collection-slider__content-view-all-wrapper {
  justify-content: center;
  background-color: rgba(16, 16, 16, 0.05);
}

.wishlist .cdk-wishlist,
.cdk-wishlist {
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-heading);
  padding: 19px;
  background: #fdfdfd;
  border: 1px solid rgba(39, 39, 39, 0.5);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.product-button-wishlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 23px;
  justify-content: space-between;
}
.product-form__cart-submit.btn {
  width: 100%;
  max-width: 226px;
  padding: 20px 20px;
}
.cdk_wishlist__block {
  width: 100%;
  max-width: 224px;
}
.quick-product--modal__inner
  .product-button-wishlist
  .product-form__cart-submit {
  max-width: 100%;
}
.quick-product--modal__inner .cdk_wishlist__block {
  max-width: 100%;
}
.quick-product--modal__inner .product-button-wishlist {
  flex-wrap: wrap;
}
#wishlist__product_container {
  grid-template-columns: repeat(3, 1fr);
  gap: 85px 30px;
}
@media screen and (max-width: 991px) {
  #wishlist__product_container {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 30px;
  }
}

#wishlist__product_container {
  padding: 75px 0;
}
#wishlist__product_container.emptyWishlist {
  display: block;
  padding: 40px 0;
}
.no__Wishlist_Product__exists {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26.4px;
  font-weight: 400;
  color: #272727;
}
.btn.cdk-btn {
  font-family: var(--fon-heading);
  font-size: 16px;
  line-height: 20px;
  max-width: 250px;
}
.search__content .image__img {
  position: relative;
}
.product-item__media {
  border: 1px solid rgba(16, 16, 16, 0.05);
}
.product-item__hover-container {
  border: transparent !important;
}
.product-item .product-badges {
  top: 14px;
  left: 0;
  padding: 0 14px;
  justify-content: space-between;
  width: 100%;
  gap: 14px;
}
.product-badge.product-badge {
  margin: 0;
}
.product__block--product-header-inner .product-badges {
  gap: 14px;
}
.product-item__hover-action-wrap {
  padding: 0 14px;
}
.recommended-products__slider-wrapper {
  display: block;
  margin-left: -19px;
}
.recommended-products__slide {
  width: 100%;
  height: 100%;
  padding-left: 19px;
}
.recommended-products__slider-wrapper {
  display: flex;
}
.wishlist--icon svg {
  display: block;
  width: 24px;
  height: 25px;
}
.remove__product.cdk_wishlist__block {
  max-width: 100%;
  margin-top: 15px;
  display: none !important;
}
.wishlist .remove__product.cdk_wishlist__block {
  display: block !important;
}
.footer__text a {
  word-break: break-word;
}
.cdk_wishlist_count {
  background-color: #f99;
  color: #272727;
  border-radius: 9px;
  min-width: 18px;
  height: 18px;
  padding: 0 0.25em;
  line-height: 18px;
  text-align: center;
  position: absolute;
  left: 1px;
  bottom: 2px;
  z-index: 2;
  font-size: 12px;
}
.quick-product .product__vendor a {
  display: none;
}
.home-text-section .rich-text__heading {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  font-family: var(--font-heading);
  text-transform: initial;
  margin-bottom: 25px;
}
.home-text-section .rich-text__text p {
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  color: #272727cc;
  margin-top: 0;
  margin-bottom: 0;
}
.wishlist .product-item__hover-action-wrap {
  display: none;
}
.wishlist .show-product-quickview {
  display: none;
}
.article-product-block {
  text-align: center;
}
.article-product-block .price {
  align-items: center;
  margin-top: 10px;
}
.article-product-block select {
  width: 100%;
  max-width: 100px;
  text-align: center;
  -webkit-appearance: auto;
}
.article-product-block .btn {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  max-width: 250px;
}
.article-product-block .thumbnail-overlay {
  margin-top: 15px !important;
}
.appstle_input_wrapper label [class*="appstle-selling-plan"] {
  width: 100%;
  max-width: 50%;
}
.widgetSellingPlanWrapper div,
.widgetSellingPlanWrapper label {
  font-family: "Montserrat";
  font-weight: 400;
}
.widgetSellingPlanWrapper .appstle-cancel-text {
  text-transform: uppercase;
  font-weight: 900;
}
/*---- 06-06-2023 ----*/

.template-collection .jdgm-widget.jdgm-widget,
.template-index .product-item .product-item__meta {
  margin-top: 5px;
}
.template-index .jdgm-preview-badge[data-template="index"] {
  display: block !important;
}
.quick-cart__form-item-properties p {
  font-size: 12px;
}
.quick-cart__form-item-properties p:last-child {
  margin-bottom: 5px;
}

@media (min-width: 960px) {
  .product__top.product__primary {
    display: flex;
    gap: 60px;
  }
  .product__primary-left {
    width: 100%;
    max-width: calc(100% - 477px);
  }
  .product__primary-right {
    max-width: 477px;
  }
  .product__vendor.main-product-top {
    margin-bottom: 3px;
  }
  .recommended-products__slider-wrapper {
    justify-content: space-between;
  }
  .recommended-products__slider-wrapper .recommended-products__slide {
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 1270px) {
  .product-button-wishlist {
    flex-wrap: wrap;
  }
  .product-form__cart-submit.btn {
    max-width: 100%;
  }
  .cdk_wishlist__block {
    max-width: 100%;
  }
}

/*---- Responsive ----*/

@media (min-width: 750px) {
  .title_large {
    font-size: 50px;
    line-height: 58px;
  }
  .btn.bottom-border-button {
    margin: 0 15px;
  }
}
@media (min-width: 992px) {
  .title_large {
    font-size: 64px;
    line-height: 78px;
  }
  .btn.bottom-border-button {
    margin: 0;
  }
}
@media screen and (min-width: 767px) and (max-width: 1216px) {
  .image-hero-split-item--2 .image-hero-split-item__text-container {
    padding-right: 20px;
  }
  .image-hero-split-item--1 .image-hero-split-item__text-container {
    padding-left: 20px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1024px) {
  .footer__newsletter .input[type="email"] {
    padding-right: 139px;
  }
  .footer__newsletter .footer__newsletter-button {
    padding: 20px 20px;
  }
}

@media screen and (max-width: 991px) {
  .footer__group {
    width: 100%;
    padding: 0;
  }
  .footer__group.footer__group--2,
  .footer__group.footer__group--3 {
    width: 50%;
  }
  .footer__group:not(:last-child) {
    margin-bottom: 75px;
  }
}
@media (max-width: 960px) {
  .footer__groups {
    flex-direction: unset;
    flex-wrap: wrap;
  }
  .footer__group.footer__group--links.footer__group--2,
  .footer__group.footer__group--links.footer__group--3 {
    width: 50%;
  }
  .footer__arrow .icon {
    display: none;
  }
  .footer__group {
    border: none;
  }
  .footer__group:not(:last-child) {
    margin-bottom: 75px;
  }
  .footer__newsletter .footer__newsletter-button {
    height: 50px;
    padding: 15px 40px;
  }
  .icon-with-text {
    flex-direction: column;
  }
  .icon-with-text-text {
    text-align: center;
    font-size: 14px;
    line-height: 19px;
  }
  .icon-with-text-heading {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .image-with-text-split__inner .image-with-text-split__text-container {
    padding: 40px 3.3vw 83px;
  }
  .product-page-faq {
    padding-top: 19px !important;
    padding-bottom: 19px !important;
  }
  .template-product
    .section-template--15793765941444__ec50468e-92c9-4f6b-bac3-675f863968b5-padding {
    padding-bottom: 0;
  }
  .product__policies {
    margin-bottom: 75px;
  }
  .icon-header-menu-new svg {
    height: 21px;
  }
  span.icon.icon-new.icon-header-menu-new {
    height: 16px;
  }
  /*   .header--has-logo .header__logo-link {
    width: 104px;
    max-width: 104px;
  } */
  .header__logo {
    padding: 20px 0;
  }
  .footer__newsletter .input[type="email"] {
    height: 50px;
    padding-top: 21px;
    line-height: 20px;
    padding-right: 10px;
  }
  .footer__bottom-right {
    display: none;
  }
  .drawer-menu__item {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: inherit;
    margin: 0 15px;
    color: #272727;
  }
  .drawer-menu__contents {
    height: 100%;
    overflow: hidden;
  }
  .social-icon-mob {
    border-top: 1px solid #272727;
    margin: 0 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .social-icon-mob a svg {
    display: block;
  }
  .drawer-menu__main {
    overflow: visible;
  }
  /* .header__icon-touch--search {
    display: none;
  } */
  ul.drawer-menu__secondary-links {
    margin-top: 0;
  }
  .drawer-search {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: inherit;
    margin: 0 15px;
    color: #272727;
    margin-top: 20px;
    display: block;
  }
  .drawer-menu__primary-links:last-child .drawer-menu__item:last-child {
    border-bottom-width: 1px;
  }
  .drawer-menu__secondary-links .drawer-menu__item .drawer-menu__link {
    height: unset;
    margin-top: 20px;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }
  .drawer-menu__list.drawer-menu__list--sub {
    background-color: #fff;
  }
  .drawer-menu__list.drawer-menu__list--sub .drawer-menu__item:last-child {
    border-width: 0;
    padding-bottom: 50px;
  }
  #wishlist__product_container {
    gap: 60px 20px;
  }
  .drawer-menu__link {
    height: unset;
    margin: 20px 0;
  }
  .drawer-menu__secondary-links .drawer-menu__item {
    margin-top: 0;
  }
  .footer__header.ff-body span.footer__heading.fs-accent {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .mobile-show {
    display: block;
  }
  .mobile-hide {
    display: none;
  }
  .mobile-quick-btn {
    display: block;
  }
  .image-split-heading {
    position: static;
    color: #272727;
    transform: inherit;
    width: 100%;
    padding: 40px 15px;
  }
  .title_large {
    font-size: 26px;
    line-height: 30px;
  }
  .image-hero-split__inner .btn.bottom-border-button {
    font-size: 16px;
    line-height: 20px;
  }
  .homepage-multicolm .multi-column__grid {
    justify-content: center;
  }
  .multi-column__grid-item-heading {
    font-size: 16px;
    line-height: 20px;
  }
  .multi-column__grid-item-text p {
    font-size: 14px;
    line-height: 19px;
  }
  .multi-column__grid-item {
    margin-bottom: 30px;
  }
  .title_medium {
    font-size: 26px;
    line-height: 32px;
  }
  .complete-the-look__subheading p {
    font-size: 16px;
    line-height: 21px;
  }
  .image-hero-split-item__text-container {
    right: inherit;
  }
  .product-card-mini__product-title,
  .product-item__text .product-item__product-title {
    font-size: 16px;
    line-height: 19px;
  }
  .testimonial-review {
    flex-direction: column;
    align-items: flex-start;
  }
  .review-text {
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 0;
    font-size: 14px;
    line-height: 23.1px;
  }
  .review-svg svg {
    display: block;
    width: 105px;
    height: 49px;
  }
  .testimonials__item-quote.ff-body {
    font-size: 20px;
    line-height: 28px;
  }
  .testimonails__carousel.swiper {
    width: 100%;
  }
  .testimonials__navigation-button {
    background: transparent;
    box-shadow: none;
  }
  .testimonials__navigation-button .icon svg {
    color: #fff;
  }
  .testimonials__item-author.ff-body {
    font-size: 16px;
    line-height: 22px;
    opacity: 1;
    margin-top: 21px;
  }
  .home-i-w-t .image-with-text-split__text-container-text p {
    font-size: 16px;
    line-height: 26px;
  }
  .home-i-w-t .image-with-text-split__image-container h2 {
    padding: 75px 15px 30px;
  }
  .home-i-w-t.image-with-text-split {
    padding-bottom: 0;
  }
  .image-with-text-split__text-container {
    padding-top: 0;
    padding-bottom: 90px;
  }
  .footer__header.ff-body {
    margin-bottom: 35px;
    padding: 0;
  }
  .footer__header.ff-body span.footer__heading.fs-accent {
    font-size: 16px;
    line-height: 26px;
  }
  .footer__newsletter .footer__newsletter-button {
    width: 100%;
    padding: 15px 40px;
  }
  .mobile-quick-btn .btn.product-card-mini.product-card-mini__inner {
    position: unset;
    transform: unset;
    background: unset;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px;
    border: 1px solid rgba(39, 39, 39, 0.5);
    width: unset;
    max-width: unset;
    height: unset;
    visibility: visible !important;
    opacity: 1 !important;
    margin-top: 19px;
  }
  .product-card-mini__price {
    padding: 15px 0 0;
  }
  .featured-collection-slider {
    padding-bottom: 19px !important;
  }
  .product-card-mini__inner.product-card-mini__hover:hover .btn {
    visibility: hidden;
    opacity: 0;
  }
  .testimonials .section-template--15793765548228__16558561940a4fe50f-padding {
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .multi-column__grid-item {
    width: 100%;
  }
  .complete-the-look {
    padding-top: 75px !important;
    padding-bottom: 19px !important;
  }
  .footer__group-content {
    display: block !important;
    padding: 0;
  }
  .complete-the-look__inner .button-block__button {
    display: block;
    width: 100%;
    max-width: 176px;
    margin-bottom: 30px;
  }
  .complete-the-look__inner .button-block__button a {
    border: 1px solid rgba(39, 39, 39, 0.5);
  }
  .complete-the-look__inner .button-block__button span {
    font-size: 16px;
    line-height: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    color: #272727;
  }
  .footer__newsletter .footer__newsletter-button {
    position: static;
    margin-top: 30px;
  }
  .footer.section {
    padding-top: 134px !important;
  }
  .icon-with-text-icon {
    margin-right: 0;
  }
  /*---- collection Page ----*/
  .collection__main-area .collection__products {
    padding-bottom: 68px;
  }
  .pagination__list + .pagination__footer {
    font-weight: 400;
    margin-top: 18px;
  }
  .collection__main-area {
    padding: 28px 2px 0;
  }
  #wishlist__product_container {
    column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  .collection.collection--has-filter-bar.section {
    padding-bottom: 75px;
  }
  .collection-banner:not(.collection--has-image):after {
    width: 92%;
  }
  /*---- Product Page -----*/
  .product-form__item .btn {
    padding: 15px 60px;
  }
  .product .accordion__label .ff-heading {
    font-size: 18px;
    line-height: 22px;
  }
  .collapsible-row-list-item__heading {
    font-size: 18px;
    line-height: 22px;
  }
  .more-faq {
    font-size: 18px;
    line-height: 22px;
  }
  .image-with-text-icon
    .image-with-text-split__inner
    .icon-with-text-container {
    padding-top: 60px;
    padding-bottom: 80px;
    background: linear-gradient(
      179.43deg,
      rgba(27, 27, 27, 0) 7.64%,
      #150a06 16.26%,
      #1b1b1b 99.51%
    );
    margin-top: -92px;
  }
  .image-with-text-icon .title_medium {
    text-align: center;
  }
  .icon-with-text-icon img {
    width: 25px;
    height: 25px;
  }
  .icon-with-text-icon .icon-with-text-heading {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .icon-with-text.icon-with-text-1 {
    margin-top: 35px;
  }
  .product__block .product__callouts-item {
    align-items: center;
    padding: 9px 0 9px 0;
    justify-content: center;
  }
  .product__option {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
  .product__color-chips[data-layout="3col"] {
    grid-template-columns: repeat(4, 1fr);
    overflow-x: scroll;
    padding-left: 16px;
    padding-right: 16px;
  }
  .product__color-chips[data-layout="3col"]::-webkit-scrollbar {
    display: none;
  }
  .product__option .product__label-wrapper {
    padding-left: 16px;
  }
  .product__chip {
    padding: 10px 14px;
    width: 100px;
  }
  .product__details {
    padding: 0 16px;
  }
  .pagination__navigation-button {
    font-size: 16px;
    line-height: 20px;
    height: unset;
  }
  .pagination__item--arrow .pagination__navigation-button {
    padding: 12px 16px;
  }
  .collection-banner:not(.collection--has-image) {
    padding-top: 55px;
    padding-bottom: 45px;
  }
  .mobile-filters.mobile-filters--has-grid {
    gap: 19px;
    padding: 8px 15px;
  }
  .product-badge.product-badge {
    font-size: 12px;
    line-height: 15px;
    padding: 3px 14px;
    width: unset;
    height: unset;
  }
  .collapsible-row-list-item__heading {
    padding-right: 10px;
  }
  .product-form__controls-group--submit {
    margin-bottom: 15px;
  }
  .product__block.product__block-callouts {
    margin-top: 0px;
  }
  .product__price-and-ratings {
    margin-top: 15px;
  }
  .inventory-counter__message.fs-body-75 {
    font-size: 14px;
    line-height: 19px;
  }
  .product__label,
  .product__vendor a {
    font-size: 14px;
    line-height: 19px;
  }
  .home-i-w-t .image-with-text-split__text-container {
    padding-top: 35px;
    padding-bottom: 75px;
    padding: 35px 15px 75px;
  }
  .home-i-w-t
    .image-with-text-split__text-container
    .image-with-text-split__text-container-text {
    margin-top: 0;
  }
  .announcement-bar__item {
    padding-top: 10px;
    padding-bottom: 9px;
  }
  .footer__group--1 .footer__text {
    margin-top: 35px;
  }
  .footer__group--newsletter .footer__header {
    margin-bottom: 29px;
  }
  .footer__bottom {
    margin-top: 40px;
  }
  .complete-the-look__inner
    .product-card-mini__inner
    .mini-product-image-hover-link
    .product-card-mini__image-link {
    width: 102px;
    margin-right: 20px;
    display: block;
    height: 100%;
  }
  .complete-the-look__inner
    .product-card-mini__inner
    .image.product-card-mini__image {
    height: 100%;
  }
  .complete-the-look__inner
    .product-card-mini__inner
    .image.product-card-mini__image
    img {
    height: 100%;
    object-fit: contain;
  }
  .product-card-mini__product-vendor {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
  }
  .product-card-mini .product-card-mini__meta .product-card-mini__price {
    margin-top: 3px;
  }
  .home-grid-image .grid-item {
    min-height: 431px;
  }
  .home-video.video-hero .video-hero__inner,
  .home-video.video-hero
    .video-hero__inner
    .video-hero__text-container-wrapper {
    min-height: 431px;
  }
  .header__inner {
    margin: auto 15px;
  }
  .section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-button-wishlist {
    gap: 15px;
  }
  .cdk-wishlist {
    padding: 14px 19px;
  }
  .product__block.product__block-callouts {
    margin-top: 0;
    display: none;
  }
  .left-side-blocks.for-mobile {
    padding: 0 15px;
  }
  .recommended-products__slider-wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }
  .recommended-products__slide {
    padding-left: 15px;
  }
  .recommended-products__slider-wrapper .slick-list.draggable {
    padding-right: 15px;
  }
  .recommended-products__slide .product-item .product-item__text {
    margin-top: 15px;
  }
  .home-text-section .rich-text__heading {
    font-size: 26px;
    line-height: 32px;
  }
  .home-text-section .rich-text__text p {
    font-size: 16px;
    line-height: 21px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .discount_text {
    font-size: 11px;
  }
  .product__block--product-header-inner .discount_text {
    font-size: 13px;
  }
}

@media screen and (max-width: 479px) {
  .cdk_wishlist_count {
    min-width: 14px;
    height: 14px;
    line-height: 14px;
    left: 5px;
    bottom: 5px;
  }
}

@media screen and (max-width: 400px) {
  .testimonials__navigation-button-wrapper {
    top: 132px;
    transform: unset;
  }
}

/*
***	VISUAL NOTES
*/
.custom-field__visual-notes-multi {
  text-align: center;
  margin-bottom: 30px;
}
.custom-field__visual-notes-multi h3.custom-field--title {
  margin-bottom: 15px;
  text-align: left;
}
.custom-field__visual-notes-multi .notes-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.custom-field__visual-notes-multi .notes-grid .fc-item {
  text-align: center;
  margin: 0 12px;
}

.custom-field__visual-notes-multi .notes-grid .fc-item .field__note-image img {
  max-width: 75px;
  max-height: 75px;
  object-fit: contain;
}

/*
***	VISUAL NOTES - Collection
*/
.template-collection .custom-field__visual-notes-multi {
  border: 1px solid #efefef;
  padding: 4px;
}

.template-collection .custom-field__visual-notes-multi .notes-grid .fc-item {
  margin: 0 4px;
}

.template-collection
  .custom-field__visual-notes-multi
  .notes-grid
  .fc-item
  .field__note-image
  img {
  max-width: 30px;
  max-height: 30px;
}

.template-collection
  .custom-field__visual-notes-multi
  .notes-grid
  .fc-item
  .field__note-title {
  line-height: 1.5;
  font-size: 8px;
}
.common-collection-desp .Container {
  padding: 0 80px;
}
.common-collection-desp {
  padding-top: 40px;
}
.common-collection-desp p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 14px;
}
.common-collection-desp h3.u-h4 {
  font-size: 16px;
  margin-bottom: 16px;
}

/* media */
@media (min-width: 1100px) {
  .template-collection .custom-field__visual-notes-multi .notes-grid .fc-item {
    margin: 0 8px;
  }

  .template-collection
    .custom-field__visual-notes-multi
    .notes-grid
    .fc-item
    .field__note-image
    img {
    max-width: 55px;
    max-height: 55px;
  }

  .template-collection
    .custom-field__visual-notes-multi
    .notes-grid
    .fc-item
    .field__note-title {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .common-collection-desp .Container {
    padding: 0 50px;
  }
  .common-collection-desp {
    padding-top: 0;
  }
}
@media (max-width: 640px) {
  .common-collection-desp .Container {
    padding: 0 9px;
  }
}
.footer .disclosure-list {
  background-color: transparent;
}
.footer .disclosure-list,
.header__cross-border .disclosure-list {
  display: flex;
  position: relative;
  top: unset;
  max-height: unset;
  border: 0px;
  padding: 0;
  min-width: unset;
  overflow-y: unset;
  align-items: center;
  min-height: 44px;
}
.footer .disclosure__toggle,
.header__cross-border .disclosure__toggle {
  display: none;
}
.footer .disclosure-list__item + .disclosure-list__item,
.header__cross-border .disclosure-list__item + .disclosure-list__item {
  margin-top: 0;
  margin-left: 14px;
}
.footer .disclosure-form,
.header__row .disclosure-form {
  margin-top: 0;
}
.disclosure-list__option-label {
  text-transform: uppercase;
}
.disclosure-list__item--current .disclosure-list__option-label {
  font-weight: bold;
}
.footer .disclosure-list__option-label {
  color: #fff;
}
.header__cross-border {
  margin-right: 15px;
}

.main-product-section .product__color-chips {
  display: flex;
  flex-wrap: wrap;
}

.main-product-section .product__color-chips .product__chip {
  min-width: 111px;
  width: fit-content;
}

/****************shipping **********************/
.quick-cart .icon-package-closed {
  display: none;
}

.quick-cart .free-shipping-bar .free-shipping-bar__message {
  color: #fff;
}
.quick-cart .free-shipping-bar {
  background-color: #000;
}

.quick-cart .free-shipping-bar__bar-progress {
  background-color: #ffffff;
}
.quick-cart .free-shipping-bar__bar:after {
  background-color: #ffffff;
}

/*---- 02-05-2023  */
/* Cart Drawer Upsell */
.template-index .product-button-wishlist {
  justify-content: center;
}
.rivo-reviews-star-rating {
  width: fit-content;
}
p.cdk_footprint_block {
  display: flex;
  align-items: flex-start;
}
.cdk_footprint_block input {
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 18px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.thumbnail-list.cdk_product_thumb:not(.slick-initialized) {
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  overflow: hidden;
}
.button-wrapper {
  grid-row-start: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-top: 20px;
}
.button-wrapper a:last-child {
  margin-left: 10px;
}
.quick-view-wrapper.show {
  display: block;
}
.option__type--case-size-number-of-bottles {
  margin-bottom: 15px;
  margin-top: 10px;
}
.option__type--bottle-size {
  display: none;
}
.rc-button-group__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.rc-button-group__options .button {
  color: #fff;
  border-radius: 0;
}
.rc-button-group__options .rc-radio.rc-radio--active.button {
  background-color: #9e7b5a;
  color: #fff;
  margin: 0;
}
.rc-button-group__options .rc-radio.button {
  background-color: transparent;
  color: #9e7b5a;
  border-radius: 0;
  flex: 1 0 50%;
  font-size: 17px;
  max-width: calc(50% - 5px);
}
.button-shop--now .loading-overlay__spinner {
  position: absolute;
  width: 20px;
  height: 20px;
}
.button-shop--now.is-loading .btn_text {
  opacity: 0;
}
.button-shop--now[data-btn="soldout"] {
  cursor: no-drop;
}
.cdk--custom_subscription_options {
  display: flex;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  flex-direction: column;
}
.cdk--custom_subscription_options .subscription_pan_item {
  border: 1px solid #9e7b5a;
  background-color: transparent;
  color: #9e7b5a;
  border-radius: var(--variant-pills-radius);
  display: block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 10px 10px;
  font-size: 14px;
  text-align: center;
  transition: border var(--duration-short) ease;
  cursor: pointer;
  position: relative;
  line-height: 1.7rem;
  letter-spacing: 0;
}
.cdk--custom_subscription_options .subscription_pan_item.active {
  background-color: #9e7b5a;
  color: #fff;
}
.product-card-wrapper.cdesk-product .rivo-reviews-rating-count {
  color: #bcb6ae;
}
.product-card-wrapper.cdesk-product
  .rivo-reviews-star-rating.cdk_quick_review
  .rivo-reviews-rating-count {
  color: #9e7b5a;
}
.cdk_close_icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.cdk_close_icon .icon-plus {
  display: block;
  transform: rotate(45deg);
  color: #1a1a1a;
  transition: 0.2s;
}
.cdk_close_icon:hover .icon-plus {
  transform: rotate(135deg);
}
.cdk_quick_title {
  margin-top: 10px;
  margin-bottom: 7px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-transform: capitalize;
  color: #272727;
}
.cdk_quick_price {
  margin-bottom: 10px;
}
.cdk_quick_price .price.price--large {
  color: #272727;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
}
.product-swatches .option_label {
  font-size: 20px;
  line-height: 26px;
  color: #272727;
  margin-bottom: 7px;
}
.cdk_recharge_text .cdk_recharge_tc_text {
  margin: 0;
  color: #7c7c7e;
  font-size: 12px;
}
.cdk_recharge_text {
  min-height: 30px;
}
.cdesk-product .card--media .card__inner .card__content {
  position: static;
}
.button-shop--now.view__product {
  display: none;
}
.announcement-bar_main:not(.slick-slider) {
  display: flex;
  overflow: hidden;
}
.announcement-bar_main:not(.slick-slider) .announcement-bar {
  flex: 0 0 100%;
}
.announcement-bar_main.slick-initialized .slick-slide {
  display: block;
  transition: unset !important;
}
.quick-view-wrapper .product-swatches .rc-widget .cdk_subscription {
  font-size: 12px;
  line-height: 15px;
}
/*---- 13-04-2023  */
.button.button-shop--now {
  padding: 1.5rem 0;
}
.quick-view-wrapper {
  background: #f5f5f5;
  padding: 25px 16px;
  position: absolute;
  width: 100%;
  z-index: 2;
  /*  height: calc(100% - 46px); */
  height: auto;
  bottom: 60px;
  border: 1px solid #272727;
  z-index: 99;
}
.cdk--custom_recharge-label {
  margin: 0;
}
.swatches-block-box ul.swatches-item-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}
.swatches-block-box ul.swatches-item-box li {
  border: 1px solid #272727;
  background-color: transparent;
  color: #272727;
  border-radius: var(--variant-pills-radius);
  display: block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 10px 10px;
  font-size: 14px;
  text-align: center;
  transition: border var(--duration-short) ease;
  cursor: pointer;
  position: relative;
  line-height: 1.7rem;
  letter-spacing: 0;
}
.swatches-block-box ul.swatches-item-box li.selected {
  background-color: #000;
  color: #fff;
}
.product-card-wrapper .rivo-reviews-star-rating {
  cursor: unset;
}
.template-index .cdk--custom_subscription_options .subscription_pan_item {
  line-height: 16px;
}
.button-shop--now:hover {
  text-decoration: none !important;
  border: unset;
}

.featured-collection-slider__content-view-all-wrapper {
  margin-left: 0;
}
.product-item__hover-action-wrap {
  display: none;
}
button.product-item__hoverless-quick-view-button.btn.btn--rounded.show-product-quickview.cdk-quick-view-mob {
  display: none;
}

.quick-view-wrapper .product-badges {
  position: relative;
  padding: 0;
  top: unset;
  padding-right: 15px;
  gap: 10px;
}

.quick-view-wrapper .product-badges .product-badge.product-badge {
  font-size: 12px;
  line-height: 17px;
  width: 100%;
  max-width: 90px;
  height: unset;
}

.swatches-block-box ul.swatches-item-box .swatches-option {
  margin: 0;
}

.swatches-block-box ul.swatches-item-box .swatches-option {
  margin: 0;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  max-width: 75px;
}
.button.button-shop--now {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-heading);
  padding: 20px 60px;
}

.wishlist .quick-view-wrapper {
  bottom: 135px;
}
@media screen and (max-width: 767px) {
  .quick-view-wrapper .product-badges .product-badge.product-badge {
    max-width: 61px;
  }
  .swatches-block-box ul.swatches-item-box .swatches-option {
    padding: 3px 7px;
    max-width: 61px;
  }
  .button.button-shop--now {
    padding: 15px 40px;
  }
  .wishlist .cdk-wishlist {
    padding: 14px 19px;
  }
  .wishlist .quick-view-wrapper {
    bottom: 115px;
  }
  .quick-view-wrapper {
    bottom: 50px;
  }
}
@media (max-width: 470px) {
  .button.button-shop--now,
  .wishlist .cdk-wishlist {
    padding: 15px 30px;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .button.button-shop--now,
  .wishlist .cdk-wishlist {
    padding: 15px;
  }
}
@media (max-width: 400px) {
  .button.button-shop--now,
  .wishlist .cdk-wishlist {
    font-size: 12px;
  }
}

/* .product-swatches {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.product-swatches > span.cdk_close_icon {
    width: 24px;
    height: 24px;
}

.product-swatches > * {
    flex: 1 0 100%;
    width: 100%;
} */

/********************10-05-2023***********************/

.homepage-multicolm {
  padding: 10px;
}
.homepage-multicolm .multi-colum-border {
  border: 2px solid #fff;
}
.homepage-multicolm .section {
  padding-top: 25px;
  padding-bottom: 25px;
}
.homepage-multicolm .multi-column__grid-item-heading {
  color: #fff;
}

.shopify-policy__title h1 {
  font-size: var(--font-size-heading-1-base);
  line-height: var(--line-height-heading);
  font-family: var(--font-heading);
  font-weight: var(--font-heading-weight);
  font-style: var(--font-heading-style);
  letter-spacing: var(--font-heading-base-letter-spacing);
  text-transform: var(--font-heading-text-transform);
}

@media screen and (max-width: 767px) {
  .shopify-policy__title h1 {
    font-size: var(--font-size-heading-2-base);
  }
}

/* SIGNUP POPUP CSS */

body div[data-testid="om-overlays-backdrop"] > div {
  height: 357px !important;
  background-size: 260px 100% !important;
  background-position: left !important;
  border: 1px solid #272727;
  padding-right: 19px !important;
  width: 100% !important;
  max-width: 650px !important;
  background-image: url(https://cdn.shopify.com/s/files/1/0039/6908/9606/files/roberthunterphotography-1899_2.png?v=1684515859) !important;
}

body div[data-testid="om-overlays-backdrop"] > div > div:first-child {
  width: 52.5% !important;
  align-self: flex-end !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:first-child {
  margin-top: 17px;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:first-child
  > div:first-child {
  font-weight: 400 !important;
  text-align: center;
  margin-bottom: 5px !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:first-child
  > div:last-child {
  margin-bottom: 0 !important;
  text-align: center;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div[data-testid="om-overlays-close"]:last-child,
body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  div[data-testid="om-overlays-close"] {
  background-image: url("https://cdn.shopify.com/s/files/1/0039/6908/9606/files/Vector_8.svg?v=1684490534") !important;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px !important;
  height: 15px !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  div[data-testid="om-overlays-close"] {
  margin-top: 0 !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > div:last-child {
  margin-top: 10px !important;
  margin-bottom: 10px;
  font-family: var(--font-body);
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #9c9c9c;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div {
  max-width: 100% !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > input {
  width: 100% !important;
  height: 50px !important;
  border: none !important;
  border-bottom: 1px solid #272727 !important;
  margin-bottom: 21px;
  padding-left: 0 !important;
  font-size: 12px !important;
  line-height: 16px;
  font-family: var(--font-body);
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > input:-webkit-autofill,
body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > input:-webkit-autofill:hover,
body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > input:-webkit-autofill:focus,
body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #272727 !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > button {
  line-height: 25px;
  height: 41px !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  > div:last-child
  > form:first-child
  > div
  > #om-overlays-error-message {
  width: 100% !important;
  position: unset !important;
  padding-bottom: 0 !important;
  font-size: 12px !important;
  text-align: center;
  line-height: 16px;
  font-family: var(--font-body);
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  div[data-testid="om-overlays-close"]
  + div:nth-child(2) {
  font-size: 36px !important;
  line-height: 41px !important;
  font-weight: 400 !important;
  text-align: center;
  margin-bottom: 7px !important;
  padding-right: 15px;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  div[data-testid="om-overlays-close"]
  + div:nth-child(2)
  + div:last-child {
  margin-bottom: 0px !important;
  text-align: center;
  padding-right: 15px;
  font-size: 17px !important;
}

body
  div[data-testid="om-overlays-backdrop"]
  > div
  > div:first-child
  div[data-testid="om-overlays-close"]
  + div:nth-child(2)
  + div:last-child:after {
  content: "We’ll be in touch soon";
  display: block;
  width: 100%;
  height: fit-content;
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-body);
  font-style: normal;
  font-weight: 400;
  color: #9c9c9c;
  padding-top: 6px;
}

@media screen and (max-width: 767px) {
  body div[data-testid="om-overlays-backdrop"] > div {
    height: 100% !important;
    max-height: 500px !important;
    background-size: 100% 45% !important;
    background-position: top !important;
    background-image: url(https://cdn.shopify.com/s/files/1/0039/6908/9606/files/roberthunterphotography-1899_4.png?v=1684516580) !important;
    justify-content: flex-end !important;
    width: 95% !important;
  }
  body div[data-testid="om-overlays-backdrop"] > div > div:first-child {
    width: 100% !important;
    max-height: 250px !important;
  }
  /*   body div[data-testid=om-overlays-backdrop] > div > div:first-child > div:first-child {
    justify-content: flex-end !important;
  } */
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    div[data-testid="om-overlays-close"]
    + div:nth-child(2) {
    padding-right: 0;
    margin-top: 13px;
    margin-bottom: 6px !important;
    font-size: 22px !important;
    line-height: 30px !important;
  }
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    div[data-testid="om-overlays-close"]
    + div:nth-child(2)
    + div:last-child {
    padding-right: 0;
    font-size: 14px !important;
    line-height: 22px !important;
  }
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    div[data-testid="om-overlays-close"]
    + div:nth-child(2)
    + div:last-child:after {
    padding-top: 7px;
  }
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    > div:last-child
    > div:last-child {
    margin-bottom: 0;
  }
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    > div:first-child
    > div:first-child {
    font-size: 25px !important;
    line-height: 25px !important;
    margin-bottom: 6px !important;
  }
  body
    div[data-testid="om-overlays-backdrop"]
    > div
    > div:first-child
    > div:first-child
    > div:last-child {
    font-size: 14px !important;
    line-height: 22px !important;
  }
}

/* Product swatches css Start */

.cdk_product_swatches .product-swatches-block {
  margin-bottom: 30px;
}

.cdk_product_swatches .product-swatches-block .product_option_label {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cdk_product_swatches
  .product-swatches-block
  .product_option_label
  .product_name_indicator {
  margin-left: 5px;
}

.cdk_product_swatches
  .product-swatches-block
  .product_swatches-block-box
  .product_swatches-item-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  grid-gap: var(--spacing-2);
}

.cdk_product_swatches
  .product-swatches-block
  .product_swatches-block-box
  .product_swatches-item-box
  .product-swatches-option {
  min-width: 111px;
  width: fit-content;
  background-color: var(--color-background-meta-alpha);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--font-heading);
  padding: 14px 29px;
  height: 39px;
  cursor: pointer;
}

.cdk_product_swatches
  .product-swatches-block
  .product_swatches-block-box
  .product_swatches-item-box
  .product-swatches-option.selected {
  background-color: #000;
  color: #fff;
}
.product-form__controls-group .appstle_subscription_wrapper {
  border-radius: 5px;
  border: 1px solid #272727;
}
#appstle_subscription_widget0 .appstle_circle {
  border: 2px solid #3c9342 !important;
  width: 22px !important;
  height: 22px !important;
}

.appstle_sub_widget span.appstle_dot {
  height: 14px;
  width: 14px;
  transform: scale(0);
  background-color: #3c9342;
  border-radius: 50%;
  flex-shrink: 0;
  transition: transform 0.2s;
}
.product-form__controls-group #appstle_subscription_widget0 {
  width: 100%;
  text-align: left;
  margin-top: 17px;
  clear: both;
  max-width: 100%;
}
#appstle_subscription_widget0 .tooltip_subscription_svg {
  fill: #3c9342 !important;
  width: 20px !important;
  height: 20px !important;
  flex-shrink: 0;
  margin-left: 18px;
}
#appstle_subscription_widget0 .appstle_tooltip_title {
  color: #606060;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
.buy_buttons_text_content {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 5px;
  margin-left: 16px;
}

.buy_buttons_text_content p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}
.appstle_subscription_wrapper select#appstle_selling_plan0 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 142.857% */
  text-transform: capitalize;
  padding-left: 10px;
  border: 1px solid #272727;
  display: flex;
  align-items: center;
}
.appstle_subscription_wrapper .appstleSelectedSellingPlanOptionDescription {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
#appstle_subscription_widget0 .appstle_select_label {
  display: flex !important;
  align-items: center;
  margin: 0;
  padding: 0;
  background: none;
  margin-bottom: 7px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
.appstle_subscription_wrapper .appstle_subscribe_save_text {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
#appstle_subscription_widget0 {
  width: 100%;
  text-align: left;
  margin-top: 17px;
  clear: both;
  max-width: 100% !important;
}
#appstle_subscription_widget0 .appstle_subscription_wrapper {
  border: 1px solid #cccccc !important;
  margin-bottom: 5px;
  margin-top: 10px;
  border-radius: inherit !important;
}
#appstle_subscription_widget0 .appstle_tooltip_wrapper {
  margin-top: 15px;
}
.cdk_product_swatches
  .product-swatches-block
  .product_swatches-block-box
  .product_swatches-item-box
  .product-swatches-option[data-availability="false"] {
  text-decoration: line-through;
  background-color: var(--color-background-meta-alpha);
  color: var(--color-text-meta);
}
#cdk_product_act[disabled] {
  pointer-events: none;
}
.discount-content + .product-badges {
  top: 40px;
}
/* Product swatches css end*/

.quick_payment_icon {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
  row-gap: 10px;
  margin-top: 15px;
}

.quick_payment_icon li {
  padding-left: 10px;
  display: flex;
}

.quick_payment_icon li .icon {
  width: 40px;
  height: auto;
}

.product-form__cart-submit.btn {
  max-width: 100%;
}

.product__block.product__block-callouts h6 {
  margin: 0;
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-family: var(--font-heading);
  text-transform: capitalize;
}

.product__block.product__block-callouts p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.01em;
  margin: 0;
}

.product__block.product__block-callouts .product__callouts-item-icon {
  margin-right: 15px;
}

.product__block .product__callouts-item {
  align-items: center;
  padding: 12px 10px;
}

.product__block.product__block-callouts .product__callouts-items {
  padding: 10px;
}

.product__callouts-items[data-with-dividers="true"]
  .product__callouts-item:not(:first-child) {
  border-top: 1px solid #272727;
}

#dynamic-checkout-cart .shopify-cleanslate .xGd3h1QpIzYq8GCXdELr {
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 12px;
  margin-top: 12px !important;
}

#dynamic-checkout-cart
  .shopify-cleanslate
  .xGd3h1QpIzYq8GCXdELr
  .XLcXEW3RnM9fyU7k7fvC,
#dynamic-checkout-cart
  .shopify-cleanslate
  .xGd3h1QpIzYq8GCXdELr
  .XLcXEW3RnM9fyU7k7fvC
  .kqsiVA9Jf8LJAbxw8Bau {
  margin: 0 !important;
  width: calc(50% - 6px);
}

.product-button-wishlist + .shopify-payment-button {
  display: none;
}

@media screen and (max-width: 767px) {
  .jdgm-widget.jdgm-widget .jdgm-prev-badge .jdgm-prev-badge__stars {
    display: block;
  }
  .product__block.product__block-callouts {
    margin-top: 30px;
    display: block;
  }
  .product__block .product__callouts-item {
    justify-content: flex-start;
    padding: 12px 5px;
  }
  .product__block.product__block-callouts .product__callouts-item-icon {
    width: 25px;
    height: 25px;
  }
  .product__block.product__block-callouts h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .product__block.product__block-callouts p {
    font-size: 14px;
    line-height: 19px;
  }
  .product-button-wishlist + .shopify-payment-button {
    display: block;
  }
  .quick-search {
    z-index: 999;
    max-width: 100%;
    right: -100%;
  }
  .quick-search.quick-search--visible .quick-search__container {
    max-width: 100%;
  }
}

/*---- Product Page Timer -----*/
.time-counter {
  border-radius: 40px;
  padding: 10px;
  background: #ccf7ff;
  color: #000000;
  text-align: center;
  margin-bottom: 35px !important;
  margin-top: 25px !important;
  font-weight: 600;
}
.time-counter span {
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.42px;
}
.time-counter .text_main,
.time-counter .tracking {
  font-weight: normal;
  font-size: 14px;
}
.bundle_button_customn {
  position: relative;
  padding: 15px 17px 14px;
  background-color: #f4ddd5;
  color: #a8573b;
  border-radius: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* border: 1px solid #a8573b; */
  transition: all 0.4s ease-in-out;
}
div#cdk_product_act {
  background-color: #000000;
  color: #ffffff;
}
div#cdk_product_act:hover {
  color: #000000;
  background-color: #ffffff;
  transition: all 0.4s ease-in-out;
}
div#cdk_product_act:before {
  display: none !important;
  transition: all 0.4s ease-in-out;
}
.product-form__controls-group.product-form__controls-group--submit {
  margin-bottom: 10px;
}
.bundle_button_customn svg {
  width: 20px;
  height: 18px;
  position: relative;
  top: -1px;
}
.bundle_button_customn:hover {
  transition: all 0.4s ease-in-out;
  background: #a8573b;
  color: #f4ddd5;
}

@media (max-width: 600px) {
  .time-counter .text_main {
    display: block;
  }
}
/*---- Product Page Timer -----*/

/*---- Header -----*/
.desktop_search {
  display: none;
}
.overflow__hidden {
  overflow: hidden;
}
.image-hero-split-item__text-container .mobile_button {
  display: none;
}
.image-split-heading.title_large.mobile_heading {
  display: none;
}
/* .mobile_trustpilot_widget {
    display: none;
} */
@media (min-width: 767px) {
  button.product-thumbnails__item-link {
    max-width: 80px;
    height: 80px;
  }
  button.product-thumbnails__item-link .image,
  button.product-thumbnails__item-link .image .image__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius:20px
  }
  .product-thumbnails__item-link.active:after{
    border-radius:20px
  }
  button.product-thumbnails__item-link .image .image__inner .image__img {
    top: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  li.product-thumbnails__item {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .product__media-container[data-gallery-style="thumbnails"] {
    gap: 10px;
  }
}
@media screen and (min-width: 960px) {
  .desktop_search {
    display: block;
    position: unset;
  }
  .header__row.header__row-desktop.lower {
    justify-content: normal;
  }
  .header__inner .header__row .header__row-segment.contains-nav {
    flex: 1;
    padding-left: 50px;
  }
  .header__icon-touch.header__icon-touch--search {
    display: none;
  }
  .desktop_search .quick-search__close {
    display: none;
  }
  .quick-search__footer {
    display: none;
  }
  .desktop_search .quick-search__form.active .quick-search__footer {
    display: block;
  }
  .desktop_search .quick-search__overlay {
    display: none !important;
  }
  .desktop_search .quick-search__container {
    overflow: unset;
  }
  .desktop_search .quick-search__form {
    position: static;
  }
  .desktop_search .quick-search__results-wrapper {
    position: unset;
  }
  .quick-search__form.active .desktop-search-result {
    display: block;
  }
  .desktop_search .desktop-search-result {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 9;
    min-height: 500px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .desktop_search .quick-search__results-wrapper {
    height: 100%;
  }
  .desktop_search .quick-search__form.active .quick-search__footer {
    display: block;
    top: 412px;
  }
  .desktop_search .quick-search__results {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 1150px) {
  .header__inner .header__row .header__row-segment.contains-nav {
    padding-left: 30px;
  }
  .header__links.header__links-primary .header__links-list li {
    margin-right: 30px;
  }
  .desktop_search .quick-search__form .quick-search__bar {
    padding-left: 0;
  }
}
@media screen and (max-width: 1050px) {
  .header__inner .header__row .header__row-segment.contains-nav {
    flex: 1;
    padding-left: 30px;
  }
  .header__links.header__links-primary .header__links-list li {
    margin-right: 20px;
  }
  .desktop_search .quick-search__form .quick-search__bar {
    padding-left: 0;
    padding-right: 15px;
  }
  html.sticky-header-enabled .shopify-section.header__outer-wrapper {
    z-index: 999;
  }
}
@media screen and (max-width: 959px) {
  .desktop_search .quick-search__form .quick-search__bar {
    padding: 0 15px;
  }
  .product__media_right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 40px;
  }
  .product__media__left {
    display: none !important;
  }
  .desktop_search .quick-search {
    z-index: 9999;
  }
  .desktop_search .quick-search__form.active {
    overflow-y: hidden;
  }
  .desktop_search .quick-search__results-wrapper {
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
  .header__row.header__row-desktop.lower {
    position: absolute;
    top: 84px;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding-bottom: 10px;
    z-index: -1;
  }
  .desktop_search {
    display: block;
    position: unset;
    width: 100%;
  }
  .header__icon-touch.header__icon-touch--search {
    display: none;
  }
  .desktop_search .quick-search__close {
    display: none;
  }
  .quick-search__footer {
    display: none;
  }
  .desktop_search .quick-search__form.active .quick-search__footer {
    display: block;
  }
  .desktop_search .quick-search__overlay {
    display: none !important;
  }

  .header__row.header__row-desktop .header__logo {
    display: none;
  }

  .header__row .header__row-segment.contains-nav {
    display: none;
  }

  .header__row-segment.header__row-segment-desktop.right
    nav.header__cross-border.fs-body-50 {
    display: none;
  }

  .header__row-segment.header__row-segment-desktop.right
    .header__icon-touch--search {
    display: none;
  }

  .header__row-segment.header__row-segment-desktop.right
    .header__icon-touch.no-transition {
    display: none;
  }
  .header__row.header__row-desktop {
    display: block;
  }
  html.sticky-header-enabled .shopify-section.header__outer-wrapper {
    z-index: 999;
  }
  .header__row.header__row-mobile {
    min-height: 60px;
  }
  main#main {
    padding-top: 54px;
  }
  header.header.search_remove .header__row.header__row-mobile {
    padding: 0;
  }
  header.header.search_remove .header__row.header__row-desktop.lower {
    display: none;
  }
}
@media screen and (max-width: 719px) {
  .image-hero-split-item--2 {
    display: none;
  }
  .image-hero-split-item .image-hero-split-item__text-container {
    align-items: center;
    justify-content: center;
    right: 0;
  }
  .image-hero-split-item__text-container .mobile_button {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 15px auto;
  }
  .button-block__button span svg,
  .btn.bottom-border-button span svg {
    display: none;
  }
  .image-hero-split-item__text-container .desktop_button {
    display: none;
  }
  .image-split-heading.title_large.desktop_heading {
    display: none;
  }
  .image-split-heading.title_large.mobile_heading {
    display: block;
    color: #fff;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .homepage-multicolm.multi-column {
    display: none;
  }
  /* .desktop_trustpilot_widget {
    display: none;
  }
  .mobile_trustpilot_widget {
    display: block;
  } */
}
.buy_buttons_text_content .svg_icon {
  width: 24px;
  height: 24px;
}
.next_icon {
  margin-top: 0;
  margin-left: 10px;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.preview_icon {
  transform: rotate(-180deg);
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.next_preview_icon {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  bottom: 0%;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
.product__media-container.above-mobile {
  position: relative;
}
.next_preview_icon {
  display: none;
}
.product__thumbnail__left .image__inner img.image__img {
  width: 100%;
  max-width: 100px;
}

@media (max-width: 767px) {
  .product__media_right {
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
  .product__media_right .image__inner img {
    border-radius: 0;
    margin-top: 15px;
  }
  .product__media_right button {
    max-width: 35px;
    max-height: 35px;
    background-size: 25px 25px !important;
  }
  .product__media_right button.slick-prev {
    right: 60px;
  }
}
a.CozyShowCRModal {
    display: flex;
    margin-right: 13px;
    height: 40px;
    align-items: center;
    z-index: 1;
    position: relative;
}
a.CozyShowCRModal img {
    z-index: 0;
}

a.CozyShowCRModal:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}
[data-simple-bundles-options] label {
    color: #003a44;
    margin-bottom: 3px;
}

[data-simple-bundles-options] select {
    margin-bottom: 15px;
    border-color: #00aff0!important;
    color: #003a44!important;
    border-radius: 8px!important;
}

@media(max-width:768px){

[data-simple-bundles-options] label {
    font-size: 14px;
}

.header__row:not(.header__row-mobile) .header__row-segment.right .CozyShowCRModal {
    display:none;
}
  .header__icon-touch.header__icon-touch--account{
    display:block;
  }
  .header__icon-touch.header__icon-touch--account svg{
    width:100%;
    margin-top: -3px;
  }
  .drawer-menu__bottom {
    background: #003A44;
}

.drawer-menu__bottom .quick-search__bar {
    background: #003A44;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
}

.drawer-menu__bottom .quick-search__bar input#search {
    width: 100%;
    max-width: 100%;
}

.drawer-menu__bottom .quick-search__bar path#Ellipse-1 {
    fill: #00AFF0;
}

a.drawer-menu__link {
    margin: 0;
}

.drawer-menu__primary-links .drawer-menu__item {
    border: none;
}

.drawer-menu__primary-links .drawer-menu__item a {
    font-family: 'Kaio';
    font-weight: 900;
    color: white;
    font-size: 28px;
    line-height: 1.5;
}

.drawer-menu__list.drawer-menu__list--sub {
    background-color: transparent;
}
  .drawer-menu__contents img.image__img {
    max-width: 62px;
    margin: auto;
}

.drawer-menu__contents .fs-body-100.footer__text {
    text-align: center;
}

.drawer-menu__contents .footer__group-content {
    border-top: 1px solid white;
    margin-left: 13px;
    margin-right: 13px;
    margin-top: 22px;
}

span.drawer-menu__item--heading-back-link {
    color: white;
}
}
nav.header__links button span {
    font-family: Kaio;
    font-weight: 900 !important;
    font-size: 15px !important;
}

nav.header__links button span.header__links-icon {
    display: none;
}

.navigation__submenu a {
    font-family: Kaio;
    font-weight: 900 !important;
    font-size: 15px !important;
}

.product-page-faq .collapsible-row-list__inner {
    display: flex;
}

.product-page-faq .collapsible-row-list__inner>* {
    width: 50%;
}

.product-page-faq .collapsible-row-list__inner h2 {
    color: #F2F36F;
    font-family: 'Kaio';
    font-weight: 900;
    font-size: 11vw;
    display: inline-block;
    line-height: 8vw;
    text-transform: none;
}

.product-page-faq button.ff-heading span {
    color: white;
    font-weight: 900;
    font-size: 26px;
}

.product-page-faq .collapsible-row-list-item__text p{
    color: white!important;
}

.product-page-faq .collapsible-row-list-item {
    border-color: #f2f36f !important;
}

.product-page-faq  .accordion__label-icons span {
    color: #f2f36f !important;
}

.product-page-faq button.more-faq {
    color: #f2f36f;
    font-size: 17px;
}

.product__callouts-item-icon.image {
    width: 36px;
    height: auto;
}

.product__block.product__block-callouts .product__callouts-items {
    border-radius: 8px;
    padding: 22px;
}

.product__block.product__block-callouts h6 {
    font-weight: 900;
    color: #003A44;
}

.product__block.product__block-callouts p {
    color: #003A44;
    font-weight: 500;
}

.product__block.product__block-callouts.product__callouts.product__block--medium {
    border-top: 1px solid #00AFF0;
    padding-top: 27px;
}

.product__block--product-header-inner .product-badges {
    display: none;
}

.product__title.title_medium {
    font-weight: 900;
    color: #003A44!important;
}

.product__meta {
    padding-top: 0;
}

.product__meta .product__price.fs-body-100 span[data-price] {
    font-size: 30px;
    font-weight: 900;
    color: #00aff0;
    font-family: Kaio;
    line-height: 1.25;
    display: block;
}

.product__meta span.text-sub-maincontent {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 1;
}

.product__meta .jdgm-prev-badge {
    display: flex!important;
    flex-direction: column-reverse;
}

.product__meta .product__price-and-ratings {
    justify-content: left;
    align-items: baseline;
    border-top: 1px solid #00AFF0;
    margin-top: 15px;
    padding-top: 15px;
}

.product__meta .product__price.fs-body-100 {
    min-width: 39%;
    flex-wrap: wrap;
}

.product__meta .jdgm-preview-badge .jdgm-star {
    font-size: 18px!important;
}

.product__meta span.jdgm-prev-badge__text {
    color: #003A44;
    line-height: 1;
}

.product__meta span.product_name_indicator {
    display: none;
}

.product__meta {
    color: #003A44;
}

.cdk_product_swatches .product-swatches-block .product_option_label {
    color: #003A44;
}

.cdk_product_swatches .product-swatches-block .product_swatches-block-box .product_swatches-item-box .product-swatches-option {
    border: 1px solid #00aff0;
    border-radius: 8px;
    background: none;
    padding: 14px 15px;
    height: 46px;
    min-width: 85px;
    color: #003A44!important;
  text-transform:uppercase;
}

.cdk_product_swatches .product-swatches-block .product_swatches-block-box .product_swatches-item-box .product-swatches-option.selected {
    color: white!important;
    background: #00aff0!important;
}

.product__top.product__primary .product__label {
    color: #003a44;
}

.product__item .quantity-input {
    border: 1px solid #00aff0;
    border-radius: 8px;
    background: none;
    height: 46px;
    min-width: 100px;
    color: #003a44 !important;
}

.product__item .quantity-input .quantity-input__button {
    padding: 10px 10px;
    color: #003A44;
}

.product__top.product__primary .quantity-input__button .icon {
    color: #003A44;
}

.product__item .quantity-input input {
    color: #003A44;
    font-weight: 400;
}

.product__controls-group.product__controls-group-quantity.product__block.product__block--medium {
    width: 35%;
    float: left;
    display: inline-block;
}

.cdk_product_swatches {
    width: 65%;
    float: left;
}

.product-form__controls-group.product-form__controls-group--submit {
    display: inline-block;
    width: 100%;
}

div#cdk_product_act, .product-form__cart-submit.btn {
    background-color: #F7C4D9;
    border-radius: 8px;
    text-transform: none;
    font-weight: 400;
    color: #003A44;
    padding: 15px 20px;
}

a.bundle_button_customn {
    background-color: #003A44;
    border-radius: 8px;
    text-transform: none;
    font-weight: 400;
    padding: 15px 20px;
    color: #F7C4D9;
}

.bundle_button_customn svg {
    display: none;
}

.product__top.product__primary .accordion:first-child .accordion__inner {
    border-bottom: 0;
    border-top: 0;
}

.product .accordion .accordion__inner .ff-heading {
    font-weight: 900;
    font-size: 30px;
    color: #00AFF0;
}

.product .accordion:first-child .accordion__inner .ff-heading {
    font-weight: 900;
    font-size: 30px;
 
}

.product__top.product__primary .accordion__content * {
    color: #003A44!important;
}

.product__top.product__primary .accordion__inner {
    border-color: #00aff0;
}
.product__media img {
    border-radius: 32px;
}
.quick-search__form.active .desktop-search-result {
    min-width: 21vw;
    left: auto;
    right: 0;
}

.custom-field.custom-field__visual-notes-multi {
    border-bottom: 1px solid #00AFF0;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.inventory-counter.product__block {
    margin-bottom: 15px;
}

.custom-field__visual-notes-multi h3.custom-field--title {
    font-size: 16px;
    margin-bottom: 10px;
}

.fc-field.field__note-title {
    font-size: 12px;
}

.custom-field__visual-notes-multi .notes-grid .fc-item:first-child {
    margin-left: 0;
}

.custom-field__visual-notes-multi .notes-grid .fc-item {
    margin: 0 3px;
}
.fc-field.field__note-image:before {content: "";width: 60px;height: 60px;display: block;position: absolute;border-radius: 100%;background: #48CCB2;left: 7.5px;top: 8.5px;}


.fc-field.field__note-image {
    height: 75px;
    width: 75px;
    position: relative;
}

.fc-field.field__note-image img {
    position: relative;
}
.inventory-counter.product__block.product__block--medium {
    display: block;
    border-bottom: 1px solid #00AFF0;
    padding-bottom: 15px;
}

span.product__label.inventory-counter__label.fs-body-100 {
    margin-right: 0;
    color: #48CCB2;
    font-weight: bold;
    font-size: 18px;
}

span.inventory-counter__message.fs-body-75 {
    color: #003A44!important;
    font-weight: bold;
    font-size: 18px;
}

.product__block--product-header {
    margin-bottom: 15px!important;
}


.inventory-counter.product__block>div {
    display: contents;
}
@media(max-width:1600px){
  span.product__label.inventory-counter__label.fs-body-100, span.inventory-counter__message.fs-body-75{
  font-size:16px;
}
}
@media(max-width:768px){

.cdk_product_swatches {
    width: 100%;
}

.product__controls-group.product__controls-group-quantity.product__block.product__block--medium {
    width: 100%;
    margin-bottom: 0;
}

.product .accordion .accordion__inner .ff-heading {
    font-size: 25px;
}

.product-page-faq .collapsible-row-list__inner {
    flex-direction: column;
}

.product-page-faq .collapsible-row-list__inner>* {
    width: 100%;
}

.product-page-faq button.ff-heading span {
    font-weight: 800;
    font-size: 22px;
    line-height: 1.2;
}

.product-page-faq .collapsible-row-list__inner h2 {
    font-size: 21vw;
    line-height: 27vw;
}

.accordion__label-icons .icon, .accordion__label-icons .icon svg {
    width: 25px;
    height: 25px;
}

.accordion__label-icons {
    width: 25px;
    min-width: 25px;
    min-height: 25px;
}

.collapsible-row-list-item__trigger {
    align-items: center;
}

.product-page-faq button.more-faq {
    margin-bottom: 20px;
}
.product .product__breadcrumbs {
    margin-bottom: 30px;
}

.template-product .main section>* {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
}

.section {
    max-width: 100%;
}

.jdgm-rev-widg {
    padding-left: 0;
    padding-right: 0;
}}
span.displayed-discount.fs-body-50 {
    display: none;
}

p.product-item__price span.sale {
    color: #00aff0!important;
    display: block;
}

body .jdgm-prev-badge[data-average-rating='0.00'] {
    display: block!important;
}

.t-subdued {
    opacity: .6;
}

.product__meta .product__price.fs-body-100 s[data-compare-price] {
    font-size: 30px;
    font-weight: 900;
    color: #003a44;
    font-family: Kaio;
    line-height: 1.25;
    display: block;
}

.product__price .product__price-displayed-discount {
    display: none;
}
@media(min-width:768px){.section {
    padding-left: 0;
    padding-right: 0;
}

.filter-bar__inner {
    padding-left: 0;
    padding-right: 0;
}

.collection__main-area {
    padding-left: 0;
    padding-right: 0;
}
}
@media(max-width:768px){
    .aboutus .multi-colum-border>.section {
        padding: 0;
    }
.multi-colum-border>.section {
    padding-left: 15px;
    padding-right: 15px;
}

.featured-collection-grid__inner {
    padding-left: 15px;
    padding-right: 15px;
}}